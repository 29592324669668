
export default class {
  static validatePasswordUppercase(password) {
    const uppercaseRegex = /(?=.*[A-Z])/;
    return uppercaseRegex.test(password);
  }

  static validatePasswordNumbers(password) {
    const numbersRegex = /(?=.*\d)/;
    return numbersRegex.test(password);
  }

  static validatePasswordLength(password) {
    const lengthRegex = /.{8,}/;
    return lengthRegex.test(password);
  }

  static validatePasswordSpecialChars(password) {
    // rather than fighting a non-winnable war of listing all special characters,
    // this is an inverse-match to normal characters and numbers.
    const specialCharsRegex = /^[a-zA-Z0-9]*$/;
    return !specialCharsRegex.test(password);
  }

  static getErrorMessage(password) {
    if (password.length === '') {
      return "can/'t be blank"
    }
    if (!this.validatePasswordLength(password)) {
      return 'is too short (minimum is 8 characters)'
    }
    let other_validations = []
    if (!this.validatePasswordUppercase(password)) {
      other_validations.push( 'upper case letter')
    }
    if (!this.validatePasswordNumbers(password)){
      other_validations.push( 'digit')
    }
    if (!this.validatePasswordSpecialChars(password)) {
      other_validations.push( 'special character')
    }
    if (other_validations.length > 0) {
      return `must contain at least one ${other_validations.join(', ')}`
    }
    return '';
  }

  static getPasswordStrengthLevel(password) {
    if (password.length === 0) {
      return "no-password";
    }
    if (!this.validatePasswordLength(password)) {
      return "very-weak";
    }
    if (this.validatePasswordUppercase(password) && this.validatePasswordNumbers(password) && this.validatePasswordSpecialChars(password)) {
      return "very-strong";
    }
    if ((this.validatePasswordUppercase(password) && this.validatePasswordNumbers(password) && !this.validatePasswordSpecialChars(password)) ||
      (this.validatePasswordUppercase(password) && !this.validatePasswordNumbers(password) && this.validatePasswordSpecialChars(password)) ||
      (!this.validatePasswordUppercase(password) && this.validatePasswordNumbers(password) && this.validatePasswordSpecialChars(password))
    ) {
      return "strong";
    }
    if (this.validatePasswordUppercase(password) || this.validatePasswordNumbers(password) || this.validatePasswordSpecialChars(password)) {
      return "medium";
    }

    return "weak";
  }

  static hideAll() {
    [...document.querySelectorAll('.password-level-hint')].forEach(el => el.classList.add('hidden'));
    [...document.querySelectorAll('.password-level')].forEach(el => el.classList.add('hidden'));
  }

  static updateHintAndProgressbar(password) {
    let level = this.getPasswordStrengthLevel(password);
    if(document.getElementsByClassName(`${level}-hint`)[0] !== undefined) {
      if(document.getElementsByClassName(`${level}-level`)[0] !== undefined) {
        document.getElementsByClassName(`${level}-level`)[0].classList.remove('hidden')
      }
      document.getElementsByClassName(`${level}-hint`)[0].classList.remove('hidden')
    }
  }

  static updateLevelCheckImages(password) {
    if (this.validatePasswordLength(password)) {
      document.getElementsByClassName(`level-characters-green`)[0].classList.remove('hidden')
      document.getElementsByClassName(`level-characters-grey`)[0].classList.add('hidden')
    } else {
      document.getElementsByClassName(`level-characters-green`)[0].classList.add('hidden')
      document.getElementsByClassName(`level-characters-grey`)[0].classList.remove('hidden')
    }

    if (this.validatePasswordUppercase(password)) {
      document.getElementsByClassName(`level-upper_case-green`)[0].classList.remove('hidden')
      document.getElementsByClassName(`level-upper_case-grey`)[0].classList.add('hidden')
    } else {
      document.getElementsByClassName(`level-upper_case-green`)[0].classList.add('hidden')
      document.getElementsByClassName(`level-upper_case-grey`)[0].classList.remove('hidden')
    }

    if (this.validatePasswordNumbers(password)) {
      document.getElementsByClassName(`level-numbers-green`)[0].classList.remove('hidden')
      document.getElementsByClassName(`level-numbers-grey`)[0].classList.add('hidden')
    } else {
      document.getElementsByClassName(`level-numbers-green`)[0].classList.add('hidden')
      document.getElementsByClassName(`level-numbers-grey`)[0].classList.remove('hidden')
    }

    if (this.validatePasswordSpecialChars(password)) {
      document.getElementsByClassName(`level-symbols-green`)[0].classList.remove('hidden')
      document.getElementsByClassName(`level-symbols-grey`)[0].classList.add('hidden')
    } else {
      document.getElementsByClassName(`level-symbols-green`)[0].classList.add('hidden')
      document.getElementsByClassName(`level-symbols-grey`)[0].classList.remove('hidden')
    }
  }

  static showLevel(password) {
    this.updateHintAndProgressbar(password)
    this.updateLevelCheckImages(password)
  }

  static showPasswordError() {
    const input = document.getElementById(`user_password`)
    let error = this.getErrorMessage(input.value);

    if (error.length > 0) {
      if(document.getElementsByClassName('password_error').length > 0) {
        document.getElementsByClassName('password_error')[0].remove()
      }
      const message = document.createElement('p');
      message.classList.add('password_error')
      message.classList.add('field_error')

      message.textContent = error
      input.parentNode.insertBefore(message, input.nextSibling);
      input.classList.add('onboarding-required-input')
    } else {
      if(document.getElementsByClassName('password_error').length > 0) {
        document.getElementsByClassName('password_error')[0].remove()
      }
      input.classList.remove('onboarding-required-input')
    }

    return error.length > 0
  }
}
